@import 'styles/includes.scss';

.MapFilterCategories {
    $root: &;
    max-width: 320px;
    width: 100%;
    height: calc(80vh - 80px); // minus the navigation
    overflow-y: scroll;

    position: absolute;
    top: 0;
    right: 0;
    background-color: #fff;
    box-shadow: $boxShadowWhite;
    transform: translateX(400px);
    transition: transform 0.2s ease-in-out;
    z-index: 2;

    &--Open {
        transform: translateX(0);
    }

    @include media(m) {
        position: relative;
        transform: initial;
    }

    &__Header {
        @extend %small-labels;
        height: 56px;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;
        padding-left: 16px;
        padding-right: 12px;
    }

    &__Clear {
        text-transform: uppercase;
        position: relative;
        padding-right: 24px;

        &:after {
            content: '';
            display: block;
            width: 16px;
            height: 16px;
            background-image: url('#{$basepath}svg/icon-trash.svg');
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    &__Drawer {
        &--IsRegionWithActive:after,
        &--IsActive:after {
            content: '';
            display: block;
            width: 4px;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $colorYellow;
            position: absolute;
        }
        &--IsRegion {
            &:before {
                content: '';
                display: block;
                width: 16px;
                height: 16px;
                background-image: url('#{$basepath}svg/icon-map-marker.svg');
                position: absolute;
                left: 12px;
                top: 50%;
                transform: translateY(-50%);
                background-size: contain;
                background-repeat: no-repeat;
            }
        }

        border-top: 1px solid $colorBlack12;
        padding: 8px 8px 8px 40px;
        display: flex;
        align-items: center;
        position: relative;
        height: 56px;

        &--Depth2 {
            background-color: $colorGrey100;
            padding: 16px 8px 16px 56px;
        }

        &--Depth3 {
            background-color: $colorGrey200;
            padding: 16px 8px 16px 72px;
        }

        &--Depth4 {
            background-color: $colorGrey300;
            padding: 16px 8px 16px 88px;
        }
    }

    &__Checkbox {
        border: 2px solid rgba($colorBlack, 0.7);
        position: absolute;
        left: 12px;
        top: 50%;
        transform: translateY(-50%);
        display: block;
        height: 16px;
        width: 16px;

        #{$root}__Drawer--IsRegion & {
            display: none;
        }

        #{$root}__Drawer--Depth2 & {
            left: 12px + 16px;
        }

        #{$root}__Drawer--Depth3 & {
            left: 12px + 36px;
        }

        #{$root}__Drawer--Depth4 & {
            left: 12px + 52px;
        }

        &--Active {
            &:before {
                content: '';
                background-color: $colorYellow;
                position: absolute;
                left: 0;
                top: 0%;
                display: block;
                height: 12px;
                width: 12px;
                background-image: url('#{$basepath}svg/icon-check.svg');
                background-repeat: no-repeat;
                background-size: 65%;
                background-position: center;
            }
        }

        &--ActiveChildren {
            &:before {
                content: '';
                background-color: $colorYellow;
                position: absolute;
                left: 0;
                top: 0%;
                display: block;
                height: 12px;
                width: 12px;
            }
            &:after {
                content: '';
                width: 6px;
                height: 2px;
                display: block;
                background-color: $colorBlack70;
                transform: translate3d(-50%, -50%, 0);
                top: 50%;
                left: 50%;
                position: absolute;
            }
        }
    }

    &__Title {
        display: block;
        @extend %navigation-titles;
        text-align: left;
        font-weight: 600;

        #{$root}__Drawer--Depth2 &,
        #{$root}__Drawer--Depth3 &,
        #{$root}__Drawer--Depth4 & {
            font-weight: normal;
        }
    }

    // &__Count {
    //     font-weight: normal;
    //     padding: 4px;
    //     margin-left: auto;
    // }

    &__Toggle {
        margin-left: auto;
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-position: center center;

        &--Grey300 {
            background-color: $colorGrey300;
        }

        &--Plus {
            background-image: url('#{$basepath}svg/icon-plus.svg');
        }

        &--Minus {
            background-image: url('#{$basepath}svg/icon-minus.svg');
        }
    }
}
