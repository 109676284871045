@import 'styles/includes.scss';

.MapFilter {
    .gm-style-iw {
        /* Remove inline styles from gm-style-iw */
        padding-top: unset !important;
        max-width: unset !important;
        max-height: unset !important;
        min-width: unset !important;
    }

    .gm-style-iw-chr {
        /* Remove inline styles from gm-style-iw-chr */
        display: unset !important;
    }

    &__NoMap {
        height: calc(100vh - 65px - 65px); // minus the navigation+filter button
        position: relative;
        background-size: cover;
        background-repeat: no-repeat;

        @include media(m) {
            height: calc(80vh - 80px); // minus the navigation
        }
    }

    &__Confirm {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        max-width: 475px;
        width: 100%;
        box-shadow: $boxShadowWhite;
        padding: 32px;
        border-radius: 2px;
        background-color: #fff;
    }

    &__ConfirmButton {
        @extend %button-titles-small;
        background-color: $colorYellow;
        padding: 12px 20px;
        margin-top: 16px;
        float: right;
    }

    &__ConfirmHeading {
        @extend %h3;
    }

    &__HelpText {
        @extend %body-copy;
    }

    &__Results {
        @extend %standard-link-small;
        padding: 8px 16px;
        position: absolute;
        border-radius: 2px;
        text-decoration: none;
        bottom: 16px;
        left: 320px + 16px;
        background-color: #fff;
        box-shadow: $boxShadowCard;
        z-index: 2;
    }

    &__List {
        display: flex;
        position: relative;
        overflow: hidden;
    }

    &__ButtonWrapper {
        height: 65px;
        padding: 8px;
    }

    &__Button {
        @extend %button-titles-small;
        background-color: $colorYellow;
        width: 100%;
        height: 100%;
    }

    &__Map {
        flex-grow: 1;
        // width: calc(100vw - 260px);
        height: calc(100vh - 65px - 65px); // minus the navigation+filter button

        @include media(m) {
            height: calc(80vh - 80px); // minus the navigation
        }
    }

    &__Cluster {
        width: 32px;
        height: 32px;
        background-color: $colorYellow;
        border-radius: 50%;
        position: relative;
        z-index: 20;
        transform: translateX(-16px);
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
    }

    &__ClusterInner {
        height: 26px;
        width: 26px;
        background-color: $colorYellow;
        // animation: pulsate 2.3s infinite;
        content: '';
        display: block;
        border-radius: 50%;
        position: absolute;
        left: 3px;
        top: 3px;
        z-index: 10;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__Poi {
        height: 16px;
        width: 16px;
        background-color: $colorYellow;
        border-radius: 50%;
        position: relative;
        box-shadow: 0 1.2px 5px 0 rgba(0, 0, 0, 0.16);
        transform: translateX(-8px);
    }
}
