@import 'styles/includes.scss';

.MapInfoWindow {
    width: 240px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: $boxShadowCard;
    position: relative;

    &__Content {
        padding: 8px;
        height: 56px;
        position: relative;
        background-color: #fff;
    }

    &__Image {
        width: 100%;
        padding-top: 56.25%;
    }

    &__Text {
        @extend %small-labels;
        font-weight: normal;
        width: calc(100% - 56px);

        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: initial;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &__Link {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        z-index: 1;

        &:focus {
            outline-offset: -2px;
        }
    }

    &__PoiArrow {
        position: absolute;
        top: 0;
        right: 0;
        background-color: $colorYellow;
        width: 56px;
        height: 56px;
        display: block;
        background-repeat: no-repeat;
        background-size: 30%;
        background-position: center center;
        background-image: url('#{$basepath}svg/icon-arrow.svg');
        transition: background-color 0.2s ease-in-out;
    }
}
